import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Divider, IconButton, Popover, Toolbar, Typography } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import MenuIcon from "@mui/icons-material/Menu";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone';
import { useLoading } from "../loader/LoaderProvider";
import { auth } from "../../firebase";

export const Header = ({ handleDrawerToggle }) => {
  const navigate = useNavigate();
  const { setLoading } = useLoading();

  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [user, setUser] = useState()


  useEffect(() => {
    const loginUser = localStorage.getItem('user');
    setUser(JSON.parse(loginUser));
  }, [])

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const signOut = async () => {
    setLoading(true);
    try {
      await auth.signOut();
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      navigate("/");
      setLoading(false);
    } catch (error) {
      console.log("signOut error", error);
      setLoading(false);
    }
  };

  return (
    <Box sx={{ width: "100%", pt: 0.4, pb: 0.5 }}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { lg: "none" } }}
        >
          <MenuIcon sx={{ color: "black" }} />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <Box
            onClick={() => handleOpen()}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              py: 1,
              px: 2,
              "&:hover": {
                background: "rgba(110, 117, 159,0.1)",
                cursor: "pointer",
              },
              borderRadius: "10px",
            }}
          >
            <Userdata
              user={user}
            />
            <KeyboardArrowDownTwoToneIcon sx={{ color: "#6e759F" }} />
          </Box>
        </Box>

        {/* User Model */}
        <Popover
          anchorEl={ref.current}
          onClose={handleClose}
          open={isOpen}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{ top: -10, left: 4 }}
        >
          <Box sx={{ minWidth: 230 }} display="flex" flexDirection="column">
            <Box
              sx={{
                display: "flex",
                px: 2.5,
                pt: 1,
                alignItems: "flex-start",
                justifyContent: "flex-start",
                width: "100%",
                my: 1,
              }}
            >
              <Userdata
                user={user}
              />
            </Box>
            {/* <Divider />
            <Box sx={{ py: 0.5 }}>
              <List sx={{ px: 1, py: 0 }} component="nav">
                <ListItem
                  sx={{ borderRadius: "8px" }}
                  button
                  onClick={handleClose}
                  component={NavLink}
                >
                  <EditIcon fontSize="small" />
                  <Typography sx={{ color: "#6e759F", fontSize: "14px" }}>
                    Edit Profile
                  </Typography>
                </ListItem>
              </List>
            </Box> */}
            <Divider />
            <Box sx={{ my: 1 }}>
              <Button color="primary" fullWidth onClick={() => signOut()}>
                <ExitToAppIcon sx={{ mr: 1 }} /> Sign out
              </Button>
            </Box>
          </Box>
        </Popover>
      </Toolbar>
    </Box>
  );
};

const Userdata = ({ user }) => {
  return (
    <>
      <Box sx={{ height: 45, width: 45 }}>
        {/* <img
          src="https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg"
          alt=""
          height="100%"
          width="100%"
          style={{ borderRadius: "25%" }}
        /> */}
        <Box sx={{
          height: "100%",
          width: "100%",
          borderRadius: "25%",
          backgroundColor: "rgba(0, 0, 0, 0.04)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
          <PersonIcon sx={{ color: "black" }} />
        </Box>
      </Box>
      <Box sx={{ ml: 1.5 }}>
        <Typography sx={{ fontWeight: 600, color: "#6e759F" }}> {user?.name} </Typography>
        <Typography sx={{ fontWeight: 600, color: "#6e759F" }}> {user?.email} </Typography>
      </Box>
    </>
  );
};

import { useEffect } from 'react'
import { useLoading } from './LoaderProvider';


function LoaderComponent() {
    const { setLoading } = useLoading()

    useEffect(() => {
        setLoading(true)
        return () => {
            setLoading(false)
        };
    }, []);

    return null
}

export default LoaderComponent
import { getDownloadURL, ref, uploadBytes, deleteObject } from "firebase/storage";
import { database, storage } from "../firebase";
import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import { useLoading } from "../components/loader/LoaderProvider";
import { ROLE } from "../constants/enums";



export const handleImageUpload = (file) => {
  const storageRef = ref(storage, "images/" + file.name);
  return uploadBytes(storageRef, file)
    .then((snapshot) => getDownloadURL(snapshot.ref))
    .catch((error) => {
      console.error("Error uploading image:", error);
      throw error;
    });
};

export const deleteImage = (imageURL) => {
  const pathAndFilename = imageURL.split('images%2F')[1].split('?')[0];
  const storagePath = 'images/' + decodeURIComponent(pathAndFilename);
  const storageRef = ref(storage, storagePath)
  deleteObject(storageRef)
}

export const isValidLatitude = (latitude) => {
  // Latitude must be a number between -90 and +90 degrees
  return !isNaN(latitude) && parseFloat(latitude) >= -90 && parseFloat(latitude) <= 90;
}

export const isValidLongitude = (longitude) => {
  // Longitude must be a number between -180 and +180 degrees
  return !isNaN(longitude) && parseFloat(longitude) >= -180 && parseFloat(longitude) <= 180;
}

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


export const getDataQuery = async (dbName, pageLimit) => {

  const usersQuery = query(collection(database, dbName), orderBy("createdAt", "desc"), limit(+pageLimit))
  const data = await getDocs(usersQuery);
  const queryData = data.docs.map((doc) => {
    return { id: doc.id, ...doc.data() };
  });
  return queryData
}

export const getTotalQuery = async (dbName) => {
  const totalEvents = collection(database, dbName);
  const querySnapshot = await getDocs(totalEvents);
  return querySnapshot.size;
}

export const isSuperAdmin = (role) => role == ROLE.SuperAdmin;

export const isAdmin = (role) => role == ROLE.Admin;

export const isUser = (role) => role == ROLE.User;
import React, { useEffect, useState } from "react";
import EventTwoToneIcon from "@mui/icons-material/EventTwoTone";
import GroupIcon from "@mui/icons-material/Group";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ContactSupportTwoToneIcon from '@mui/icons-material/ContactSupportTwoTone';
import QueueMusicTwoToneIcon from '@mui/icons-material/QueueMusicTwoTone';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import Groups3Icon from '@mui/icons-material/Groups3';
import { Box, List, ListItem, ListItemButton, ListItemText, listItemClasses, } from "@mui/material";
import { NavLink } from "react-router-dom";
import { ROLE } from "../../constants/enums";

function SidebarMenu() {
  const style = { color: "#ffffffb3", mr: 1 };
  const [role, setRole] = useState("")

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setRole(user?.role)
  }, [])

  return (
    <Box sx={{ px: 2 }}>
      {role == ROLE.SuperAdmin && (
        <SideBarLink
          title={"Clubs"}
          url={"/clubs"}
          icon={<Groups3Icon fontSize="small" sx={style} />}
        />
      )}
      <SideBarLink
        title={"Events"}
        url={"/events"}
        icon={<EventTwoToneIcon fontSize="small" sx={style} />}
      />
      {role == ROLE.SuperAdmin && (
        <>
          <SideBarLink
            title={"Users"}
            url={"/users"}
            icon={<GroupIcon fontSize="small" sx={style} />}
          />
          <SideBarLink
            title={"Admins"}
            url={"/admins"}
            icon={<AccountCircleIcon fontSize="small" sx={style} />}
          />
          <SideBarLink
            title={"Contact Us"}
            url={"/contact-us"}
            icon={<PermContactCalendarIcon fontSize="small" sx={style} />}
          />
          <SideBarLink
            title={"Club Inquiries"}
            url={"/club-inquiries"}
            icon={<ContactSupportTwoToneIcon fontSize="small" sx={style} />}
          />
          <SideBarLink
            title={"Genres"}
            url={"/genres"}
            icon={<QueueMusicTwoToneIcon fontSize="small" sx={style} />}
          />
        </>
      )
      }
      {/* <SideBarLink
        title={"Sign Out"}
        // url={"/"}
        icon={<ExitToAppIcon fontSize="small" sx={style} />}
      /> */}
    </Box>
  );
}

export default SidebarMenu;

const SideBarLink = ({ title, icon, url }) => {

  const MyNavLink = (props) => <NavLink {...props} activeclassname="active" />;

  const styleList = {
    [`& .active, & .${listItemClasses.root}:hover`]: {
      transition: "0.3s",
      background: "#ffffff4d",
      color: "#fff",
      fontWeight: "700",
      "& svg": {
        transition: "0.3s",
        fill: "#fff",
      },
    },
  };

  return (
    <List sx={styleList}>
      <ListItem component={MyNavLink} to={url} sx={{ py: 0, color: "#ffffffb3", "&:hover": { background: "#ffffff4d" }, borderRadius: 3 }} >
        <ListItemButton sx={{ px: 0 }}>
          {icon}
          <ListItemText primary={title} primaryTypographyProps={{ fontSize: 14, fontWeight: 600 }} />
        </ListItemButton>
      </ListItem>
    </List>
  );
};

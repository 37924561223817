import { useState } from "react";
import { AppBar, Box, Divider, Drawer } from "@mui/material";

import SidebarMenu from "./SidebarMenu";
import { Header } from "./Header";

const SideBarLayout = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const drawerWidth = 300;
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <AppBar position="fixed" sx={{ background: '#fff' }}>
        <Header
          handleDrawerToggle={handleDrawerToggle}
        />
      </AppBar>
      <Box component="nav" sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }} >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{ display: { xs: 'block', lg: 'none' }, '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, background: '#171733' } }}
        >
          {/* <Toolbar sx={{ height: 65 }} /> */}
          <SideBarLogo />
          <Divider sx={{ borderColor: "#ffffff4d", my: 1 }} />
          <SidebarMenu />
        </Drawer>

        <Drawer
          variant={"permanent"}
          sx={{ display: { xs: 'none', lg: 'block' }, '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, background: '#171733' } }} open
        >
          {/* <Toolbar sx={{ height: 65 }} /> */}
          <SideBarLogo />
          <Divider sx={{ borderColor: "#ffffff4d", my: 1 }} />
          <SidebarMenu />
        </Drawer>
      </Box>
      <Box sx={{ flex: 1, pt: 14, pb: 4, overflowX: 'hidden', width: { lg: `calc(100% - ${drawerWidth}px)` } }} >
        {children}
      </Box>
    </Box>
  );

}

const SideBarLogo = () => {
  const image = require("../../assets/logo.png")
  return (
    <Box sx={{ textAlign: "center", mt: 2 }}>
      <img src={image} height={110} width={120} />
    </Box>
  )
}

export default SideBarLayout
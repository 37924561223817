import { Suspense, lazy, useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import LoaderProvider from "./components/loader/LoaderProvider";
import SideBarLayout from "./components/sidebar/SideBarLayout";
import LoaderComponent from "./components/loader/LoaderComponent";
import { ROLE } from "./constants/enums";
import { isSuperAdmin } from "./utils";
import ClubProvider from "./components/contexts/GlobalContext";

const Loader = (Component) => (props) => {
  return (
    <Suspense fallback={<LoaderComponent />}>
      <Component {...props} />
    </Suspense>
  );
};

const Login = Loader(lazy(() => import("./screens/Login/Login")));
const EventDeatils = Loader(lazy(() => import("./screens/Events/Events")));
const CreateEvent = Loader(lazy(() => import("./screens/Events/CreateEvent")));
const ViewEvent = Loader(lazy(() => import("./screens/Events/ViewEvent")));
const Users = Loader(lazy(() => import("./screens/Users/Users")));
const AdminUser = Loader(lazy(() => import("./screens/Admin/AdminUsers")));
const CreateAdmin = Loader(lazy(() => import("./screens/Admin/CreateAdmin")));
const AccessDenied = Loader(lazy(() => import("./screens/Permissions/AccessDenied")));
const ClubsDeatils = Loader(lazy(() => import("./screens/Clubs/Clubs")));
const CreateClub = Loader(lazy(() => import("./screens/Clubs/CreateClubs")));
const ContactUs = Loader(lazy(() => import("./screens/ContactUs/ContactUs")));
const ClubInquiries = Loader(lazy(() => import("./screens/ClubInquiries/ClubInquiries")));
const Genres = Loader(lazy(() => import("./screens/Genres/Genres")));

function App() {
  const theme = createTheme();

  const PublicRoute = () => {
    const token = localStorage.getItem("token");
    if (token?.length > 0) {
      return <Navigate to="/clubs" />;
    } else return <Outlet />;
  };

  const PrivateRoute = () => {
    const token = localStorage.getItem("token");
    return token ? (
      <SideBarLayout>
        <Outlet />
      </SideBarLayout>
    ) : (
      <Navigate to="/" />
    );
  };
  const UserPermissions = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return isSuperAdmin(user?.role) ? <Users /> : <AccessDenied />
  }

  const AdminPermissions = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return isSuperAdmin(user?.role) ? <AdminUser /> : <AccessDenied />
  }

  const CreateAdminPermissions = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return isSuperAdmin(user?.role) ? <CreateAdmin /> : <AccessDenied />
  }

  const ContactUsPermissions = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return isSuperAdmin(user?.role) ? <ContactUs /> : <AccessDenied />
  }

  const ClubInquiriesPermissions = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return isSuperAdmin(user?.role) ? <ClubInquiries /> : <AccessDenied />
  }

  const GenresPermissions = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return isSuperAdmin(user?.role) ? <Genres /> : <AccessDenied />
  }

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <LoaderProvider>
        <ClubProvider>
          <CssBaseline />
          <BrowserRouter>
            <Routes>

              <Route path="/" element={<PublicRoute />}>
                <Route path="" element={<Login />} />
              </Route>

              <Route path="events" element={<PrivateRoute />}>
                <Route path="" element={<EventDeatils />} />
                <Route path="create-event" element={<CreateEvent />} />
                <Route path=":id" element={<ViewEvent />} />
              </Route>

              <Route path="users" element={<PrivateRoute />}>
                <Route path="" element={<UserPermissions />} />
              </Route>

              <Route path="admins" element={<PrivateRoute />}>
                <Route path="" element={<AdminPermissions />} />
                <Route path="create-admin" element={<CreateAdminPermissions />} />
              </Route>

              <Route path="clubs" element={<PrivateRoute />}>
                <Route path="" element={<ClubsDeatils />} />
                <Route path="create-club" element={<CreateClub />} />
              </Route>

              <Route path="contact-us" element={<PrivateRoute />}>
                <Route path="" element={<ContactUsPermissions />} />
              </Route>

              <Route path="club-inquiries" element={<PrivateRoute />}>
                <Route path="" element={<ClubInquiriesPermissions />} />
              </Route>

              <Route path="genres" element={<PrivateRoute />}>
                <Route path="" element={< GenresPermissions />} />
              </Route>

            </Routes>
          </BrowserRouter>
        </ClubProvider>
      </LoaderProvider>
    </ThemeProvider>
  );
}

export default App;

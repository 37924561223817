import { Backdrop, CircularProgress } from '@mui/material'
import React, { createContext, useContext, useState } from 'react'

const LoaderContext = createContext()

function LoaderProvider({ children }) {
    const [loading, setLoading] = useState(false)
    return (
        <LoaderContext.Provider value={{ loading, setLoading }}>
            {children}
            <Backdrop open={loading} sx={{ zIndex: 999999999, background: "rgba(0,0,0,0.5)" }} >
                <CircularProgress size={60} sx={{ color: "#fff" }} />
            </Backdrop>
        </LoaderContext.Provider>
    )
}
export default LoaderProvider
export const useLoading = () => useContext(LoaderContext)


import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBRSkET74k_6ErkT_o9ZlOiFhkoGtcM7yo",
  authDomain: "cheers-c5745.firebaseapp.com",
  projectId: "cheers-c5745",
  storageBucket: "cheers-c5745.appspot.com",
  messagingSenderId: "670163690673",
  appId: "1:670163690673:web:bec5832c7c13eb06e36e90",
  measurementId: "G-WKZTN95J7D"
};

const app = initializeApp(firebaseConfig);
const database = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);


export { database, storage, auth };